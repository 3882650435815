

    import React, {useState} from 'react';
    import '../css/bootstrap.min.css'; // Import your CSS file for styling
    import '../css/style.css';
    import discountimg from "../images/plan-tenure.png";
    import {useNavigate} from "react-router-dom";

    const SubscriptionPlans = () => {

        const [isYearly, setIsYearly] = useState(false);

        const handleToggle = () => {
            setIsYearly(!isYearly);
        };

        const formatPrice = (price) => {
            return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        };

        const getMonthlyPrice = (monthlyPrice) => {
            return isYearly ? monthlyPrice * 0.95 : monthlyPrice;
        };

        const getAnnualPrice = (monthlyPrice) => {
            return monthlyPrice * 12 * 0.95;
        };

        const navigate = useNavigate();

        const handleNavigation = () => {
            navigate('/requestplan#RequestPlan');
        };


        return (
            <section className="onir-plans" id="pricing">
                <div className="container">
                    <h3 className="section-title">Тарифные планы</h3>
                    <p className="section-subtitle">Выберите подходящий для вас план подписки.</p>
                    <div className="plan-tenure">
                        <span className={!isYearly ? "active" : ""}>Месяц</span>
                        <label className="switch">
                            <input type="checkbox" checked={isYearly} onChange={handleToggle} />
                            <span className="slider round"></span>
                        </label>
                        <span className={isYearly ? "active" : ""}>Год</span>
                        <img src={discountimg} alt="offer" />
                    </div>
                    <div className="plan-cards mobile-cards">
                        <div className="plan-card card1">
                            <div className="plan-name">Стандартный</div>
                            <span>Для одной АЗС</span>
                            <div className="plan-amount">
                                <h3>{formatPrice(getMonthlyPrice(10000))} ₽</h3>
                                <span>/месяц</span>
                                {isYearly && <p className="annualPrice">{formatPrice(getAnnualPrice(10000))} ₽/год</p>}
                            </div>
                            <button className="btn btn-primary" onClick={handleNavigation}>Подключить</button>
                            <ul className="plan-features">
                                <li><i className="fa-solid fa-circle-check"></i>Подключение одной АЗС</li>
                                <li><i className="fa-solid fa-circle-check"></i>Один аккаунт для сотрудников</li>
                                <li><i className="fa-solid fa-circle-check"></i>До 10 сотрудников на АЗС</li>
                            </ul>
                        </div>
                        <div className="plan-card card2 active">
                            <div className="popularity">Оптимальный</div>
                            <div className="plan-name">Бизнес</div>
                            <span>Для небольшой сети АЗС</span>
                            <div className="plan-amount l-digit">
                                <h3>{formatPrice(getMonthlyPrice(25000))} ₽</h3>
                                <span>/месяц</span>
                                {isYearly && <p className="annualPrice">{formatPrice(getAnnualPrice(25000))} ₽/год</p>}
                            </div>
                            <button className="btn btn-primary" onClick={handleNavigation}>Подключить</button>
                            <ul className="plan-features">
                                <li><i className="fa-solid fa-circle-check"></i>Подключение до 3-х АЗС</li>
                                <li><i className="fa-solid fa-circle-check"></i>До 3 аккаунтов для сотрудников</li>
                                <li><i className="fa-solid fa-circle-check"></i>До 20 сотрудников на всех АЗС</li>
                                <li style={{ color: 'gold' }}><i className="fa-solid fa-circle-check"></i>Подключение Топаз Офис</li>
                            </ul>
                        </div>
                        <div className="plan-card card3">
                            <div className="plan-name">Pro</div>
                            <span>Для сети АЗС</span>
                            <div className="plan-amount">
                                <h3>{formatPrice(getMonthlyPrice(80000))} ₽</h3>
                                <span>/месяц</span>
                                {isYearly && <p className="annualPrice">{formatPrice(getAnnualPrice(80000))} ₽/год</p>}
                            </div>
                            <button className="btn btn-primary" onClick={handleNavigation}>Подключить</button>
                            <ul className="plan-features">
                                <li><i className="fa-solid fa-circle-check"></i>Подключение до 10 АЗС</li>
                                <li><i className="fa-solid fa-circle-check"></i>До 10 аккаунтов для сотрудников</li>
                                <li><i className="fa-solid fa-circle-check"></i>До 60 сотрудников на всех АЗС</li>
                                <li ><i className="fa-solid fa-circle-check" style={{ color: 'blue' }} ></i>Подключение Топаз Офис</li>
                            </ul>
                        </div>
                    </div>

                    <div>
                        <b id="SmallTextIntheMiddle2">Стоимость подключения одного АЗС 5,000 ₽ (удаленно), С выездом на объект - 10,000 ₽ за АЗС.</b>
                    </div>

                    <div className="plan-card card4">
                        <div className="plan-name">Корпоративный</div>
                        <span>Для больших сетей АЗС</span>
                        <div className="plan-amount">
                            <h3>Подключим все ваши АЗС.</h3>
                        </div>
                        <button className="btn btn-primary" onClick={handleNavigation}>Узнать Стоимость</button>
                    </div>
                </div>
            </section>
        );
    };

    export default SubscriptionPlans;









// import React, { useState, useEffect } from 'react';
// import '../css/bootstrap.min.css';
// import '../css/style.css';
// import discountimg from '../images/plan-tenure.png';
// import { useNavigate } from 'react-router-dom';
//
// const SubscriptionPlans = () => {
//     const [plans, setPlans] = useState([]);
//     const [isYearly, setIsYearly] = useState(false);
//     const [error, setError] = useState(null);
//     const navigate = useNavigate();
//
//     const fetchPlans = async () => {
//         try {
//             const requestOptions = {
//                 method: 'GET',
//                 mode: 'same-origin',
//                 credentials: 'include',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Accept': 'application/json',
//                     'Authorization': 'Basic aipfYXV0aF9mZiVnZyQ6XiVoZmo5SGRqJkBuaEoxMjMwVmJjc2NzYWZ1XiViRA=='
//                 },
//             };
//             const response = await fetch('https://myazs.online/webapi/subscription-plans', requestOptions);
//             if (!response.ok) {
//                 throw new Error(`HTTP error! status: ${response.status}`);
//             }
//             const data = await response.json();
//             setPlans(data);
//         } catch (error) {
//             console.error('Error fetching subscription plans:', error);
//             setError(error.message);
//         }
//     };
//
//     useEffect(() => {
//         fetchPlans();
//     }, []);
//
//     const handleToggle = () => {
//         setIsYearly(!isYearly);
//     };
//
//     const formatPrice = (price) => {
//         return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
//     };
//
//     const getFinalPrice = (price, discountPercent, discountExpDate) => {
//         if (discountPercent > 0 && discountExpDate) {
//             const currentDate = new Date();
//             const expDate = new Date(discountExpDate);
//             if (expDate > currentDate) {
//                 return price - (price * discountPercent / 100);
//             }
//         }
//         return price;
//     };
//
//     const handleNavigation = () => {
//         navigate('/requestplan#RequestPlan');
//     };
//
//     if (error) {
//         return (
//             <section className="onir-plans" id="pricing">
//                 <div className="container">
//                     <h3 className="section-title">Тарифные планы</h3>
//                     <p className="section-subtitle">Не удалось загрузить планы подписки. Попробуйте позже.</p>
//                 </div>
//             </section>
//         );
//     }
//
//     return (
//         <section className="onir-plans" id="pricing">
//             <div className="container">
//                 <h3 className="section-title">Тарифные планы</h3>
//                 <p className="section-subtitle">Выберите подходящий для вас план подписки.</p>
//                 <div className="plan-tenure">
//                     <span className={!isYearly ? "active" : ""}>Месяц</span>
//                     <label className="switch">
//                         <input type="checkbox" checked={isYearly} onChange={handleToggle} />
//                         <span className="slider round"></span>
//                     </label>
//                     <span className={isYearly ? "active" : ""}>Год</span>
//                     <img src={discountimg} alt="offer" />
//                 </div>
//                 <div className="plan-cards mobile-cards">
//                     {plans.length > 0 ? plans.map(plan => {
//                         const finalMonthlyPrice = getFinalPrice(plan.price, plan.discount_Percent, plan.discount_exp_date);
//                         const finalAnnualPrice = finalMonthlyPrice * 12 * (1 - plan.annual_Discount / 100);
//
//                         return (
//                             <div className="plan-card" key={plan.id}>
//                                 <div className="plan-name">{plan.level}</div>
//                                 <span>{plan.subtitle}</span>
//                                 <div className="plan-amount">
//                                     <h3>{formatPrice(finalMonthlyPrice)} ₽</h3>
//                                     <span>/месяц</span>
//                                     {isYearly && <p className="annualPrice">{formatPrice(finalAnnualPrice)} ₽/год</p>}
//                                 </div>
//                                 <button className="btn btn-primary" onClick={handleNavigation}>Подключить</button>
//                                 <ul className="plan-features">
//                                     {plan.features.split(',').map((feature, index) => (
//                                         <li key={index}><i className="fa-solid fa-circle-check"></i>{feature}</li>
//                                     ))}
//                                 </ul>
//                             </div>
//                         );
//                     }) : <p>Loading plans...</p>}
//                 </div>
//
//                 <div>
//                     <b id="SmallTextIntheMiddle2">Стоимость подключения одного АЗС: с использованием VPN сервера - 10,000 ₽ за АЗС, через ваш статический IP - 5,000 ₽ за АЗС.</b>
//                 </div>
//
//                 <div className="plan-card card4">
//                     <div className="plan-name">Корпоративный</div>
//                     <span>Для больших сетей АЗС</span>
//                     <div className="plan-amount">
//                         <h3>Подключим все ваши АЗС.</h3>
//                     </div>
//                     <button className="btn btn-primary" onClick={handleNavigation}>Узнать Стоимость</button>
//                 </div>
//             </div>
//         </section>
//     );
// };
//
// export default SubscriptionPlans;