import React, { useEffect, useState } from 'react';
import './css/bootstrap.min.css';
import './css/media.css';
import './css/style.css';
import './css/owl.carousel.min.css';
import Header from "./components/Header";
import Footer from "./components/Footer";
import page_head_vector_1 from "./images/page-head-vector-1.png";
import page_head_vector_2 from "./images/page-head-vector-2.png";
import page_head_vector_3 from "./images/page-head-vector-3.png";
import page_head_vector_4 from "./images/page-head-vector-4.png";
import myazsclient from "./images/myazsclient-img.png";

const Materials = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [isDownloading, setIsDownloading] = useState(false);
    const [downloadError, setDownloadError] = useState(false); // New state for error handling

    const requestFileFromServer = () => {
        setIsDownloading(true);
        setDownloadError(false); // Reset error state on new download attempt
        fetch('https://myazs.online/webapi/getmyazsclient', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Basic aipfYXV0aF9mZiVnZyQ6XiVoZmo5SGRqJkBuaEoxMjMwVmJjc2NzYWZ1XiViRA==',
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.blob();
            })
            .then(blob => {
                if (blob.size === 0) {
                    throw new Error('Received empty file from server.');
                }

                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'myazsClient.zip';
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);

                setIsDownloading(false);
            })
            .catch(error => {
                console.error('Error fetching the file:', error);
                setDownloadError(true); // Set error state to true
                setIsDownloading(false);
            });
    };

    // OneDrive fallback link
    const oneDriveLink = "https://disk.yandex.ru/d/hYVlQMwFcqU_Xg";

    return (
        <div className="wrapper">
            <Header />

            <div className="main-content contact-sec">
                <section className="page-head" id="page-head-about">
                    <span>Для работы</span>
                    <h2>Материалы</h2>
                    <div className="page-vectors">
                        <img className="page-vector-1" src={page_head_vector_1} alt="page-vector" />
                        <img className="page-vector-2" src={page_head_vector_2} alt="page-vector" />
                        <img className="page-vector-3" src={page_head_vector_3} alt="page-vector" />
                        <img className="page-vector-4" src={page_head_vector_4} alt="page-vector" />
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="blog-features">
                            <div className="row">
                                <div className="col-lg-5 col-sm-12">
                                    <div className="blog-feature-img">
                                        <img src={myazsclient} alt="myazsclient" />
                                    </div>
                                </div>
                                <div className="col-lg-7 col-sm-12">
                                    <h3 className="section-title" id="materials-title">
                                        Мой АЗС Клиент
                                    </h3>
                                    <p className="section-subtitle" style={{ textAlign: 'justify' }}>
                                        Подключите АЗС или Топаз Офис через Мой АЗС клиент это позволит создать безопасное
                                        VPN соединение которое будет стабильно работать без статического IP или роутора.
                                        Достаточно интернет подключения через провайдера или модем.
                                    </p>
                                    <button
                                        onClick={requestFileFromServer}
                                        className="download-btn"
                                        disabled={isDownloading}
                                    >
                                        {isDownloading ? (
                                            <>
                                                Загрузка...
                                                <span className="spinner"></span>
                                            </>
                                        ) : (
                                            'Скачать'
                                        )}
                                    </button>

                                    {/* Conditionally render the OneDrive fallback link */}
                                    {downloadError && (
                                        <div className="fallback-download">
                                            <p>Не удалось скачать файл. Пожалуйста, <a href={oneDriveLink} target="_blank" rel="noopener noreferrer">скачайте файл здесь</a>.</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer />
        </div>
    );
};

export default Materials;
